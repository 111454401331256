import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';
// utils
import axios, { endpoints } from 'src/utils/axios';
//
import { AuthContext } from './auth-context';
import { isValidToken, setSession } from './utils';
import useFirebaseApp from 'src/hooks/use-firebase-app';
import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from 'firebase/auth';

import { homeActions } from 'src/store/store';
import { useDispatch, useSelector } from 'react-redux';

import { encrypt, decrypt } from 'src/utils/custom-function';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  loading: true,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';
const STORAGE_USER_KEY = 'user';

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storeDispatch = useDispatch();
  const { firebaseAuth } = useFirebaseApp();

  // IF NEEDS TO GIVE ENTIRE DASHBOARD ACCESS, TURN IT TRUE
  const GIVE_ALL_ACCESS = false;

  const userData = useSelector((state) => state.home.user);

  // LOGOUT
  const logout = useCallback(async () => {
    signOut(firebaseAuth);

    setSession(null);

    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const initialize = useCallback(async () => {
    try {
      const accessToken = sessionStorage.getItem(STORAGE_KEY);
      const userCipherText = sessionStorage.getItem(STORAGE_USER_KEY);

      console.log(accessToken);
      console.log(userCipherText);

      if (accessToken && isValidToken(accessToken) && userCipherText) {
        setSession(accessToken);

        dispatch({
          type: 'INITIAL',
          payload: {
            user: userCipherText,
          },
        });

        const userData = decrypt(userCipherText);
        console.log(userData);

        storeDispatch(
          homeActions.setUser(JSON.parse(userData))
        )
      } else {
        dispatch({
          type: 'INITIAL',
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      logout();
    }
  }, [logout]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // ACCESS TOKEN
  const requestAccessToken = useCallback(async () => {
    console.log("Access token requested");
    const response = sessionStorage.getItem(STORAGE_KEY);
    console.log("Access token received: " + response);

    return response;
  }, []);

  // LOGIN
  const login = useCallback(async (email, password) => {
    const firebaseResponse = await signInWithEmailAndPassword(firebaseAuth, email, password);
    console.log({ firebaseResponse });

    const accessToken = await firebaseAuth.currentUser?.getIdToken();

    return accessToken;
  }, []);

  const forgotPassword = useCallback(async (email) => {
    console.log("Forgot password requested for " + email);
    const response = await sendPasswordResetEmail(firebaseAuth, email);
    return response;
  }, []);

  const requestUpdatePassword = useCallback(async (newPassword) => {
    console.log("Update password requested");
    const response = await updatePassword(firebaseAuth.currentUser, newPassword);
    return response;
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const data = {
      email,
      password,
      firstName,
      lastName,
    };

    const response = await axios.post(endpoints.auth.register, data);

    const { accessToken, user } = response.data;

    sessionStorage.setItem(STORAGE_KEY, accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  }, []);

  // LOGIN RESPONSE
  const saveLoginResponse = useCallback((customResponse) => {
    console.log("User data...");
    console.log(customResponse);
    setSession(customResponse.accessToken);

    const userObj = {
      "email": customResponse.email ? customResponse.email : '',
      "firstName": customResponse.firstName ? customResponse.firstName : '',
      "lastName": customResponse.lastName ? customResponse.lastName : '',
      "profile": customResponse.profile ? customResponse.profile: '',
      "uuid": customResponse.uuid ? customResponse.uuid : '',
      "role": customResponse.role?.name ? customResponse.role.name : '',
      "privileges": customResponse.role?.privileges ? customResponse.role?.privileges : [],
    }

    const user = JSON.stringify(userObj);
    console.log("User...");
    console.log(user);

    const encryptedData = encrypt(user);
    sessionStorage.setItem(STORAGE_USER_KEY, encryptedData);

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });

    storeDispatch(
      homeActions.setUser(userObj)
    )
  }, []);

  // CHECK ACCESS
  const hasAccess = useCallback((action) => {
    if(GIVE_ALL_ACCESS){
      return true;
    }

    console.log(action);
    console.log(userData);

    for(let i = 0; i < userData.privileges.length; i++){
      if(userData.privileges[i].name === action){
        return true;
      }
    }

    return false;
  }, [userData]);
  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      requestAccessToken,
      login,
      register,
      forgotPassword,
      requestUpdatePassword,
      logout,
      saveLoginResponse,
      hasAccess
    }),
    [requestAccessToken, login, logout, register, forgotPassword, requestUpdatePassword, saveLoginResponse, hasAccess, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};