import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const analyticsAPI = createApi({
    reducerPath: "analyticsAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAnalyticsData: builder.query({
            query: ({type, startDate, endDate}) => {
                let apiUrl = `analytics/${type}`;

                if(startDate){
                    apiUrl += `?startDate=${startDate}`;

                    if(endDate){
                        apiUrl += `&endDate=${endDate}`;
                    }
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        })
    })
});

export const { 
    useGetAnalyticsDataQuery
} = analyticsAPI;

export default analyticsAPI;