import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const masterDataAPI = createApi({
    reducerPath: "masterDataAPI",
    baseQuery,
    endpoints: (builder) => ({
        getSpecificMasterData: builder.mutation({
            query: ({type, key}) => {
                let apiUrl = `admin/master-data/${type}`;

                if(key){
                    apiUrl += `?key=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        getSpecificMasterDataQuery: builder.query({
            query: (type) => {
                let apiUrl = `admin/master-data/${type}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        createSpecificMasterData: builder.mutation({
            query: ({ type, data }) => {
                return {
                    url: `admin/master-data/${type}`,
                    method: "POST",
                    body: data
                };
            },
            invalidatesTags: ["vouchers"],
        }),
        updateSpecificMasterData: builder.mutation({
            query: ({ type, id, data }) => {
                return {
                    url: `admin/master-data/${type}/update/${id}`,
                    method: "PUT",
                    body: data
                };
            }
        }),
        getSubCasteWithCasteID: builder.mutation({
            query: ({ id }) => {
                return {
                    url: `admin/master-data/caste/${id}/sub`,
                    method: "GET"
                };
            },
            providesTags: ['subCasteList']
        }),
    })
});

export const { 
    useGetSpecificMasterDataMutation,
    useGetSpecificMasterDataQueryQuery,
    useCreateSpecificMasterDataMutation,
    useUpdateSpecificMasterDataMutation,
    useGetSubCasteWithCasteIDMutation
} = masterDataAPI;

export default masterDataAPI;