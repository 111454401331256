// routes
import { paths } from 'src/routes/paths';
// utils
import axios from 'src/utils/axios';

// ----------------------------------------------------------------------

function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}

// ----------------------------------------------------------------------

export const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

// ----------------------------------------------------------------------

export const tokenExpired = (exp) => {
  console.log("Token expiration setup called...");

  // eslint-disable-next-line prefer-const
  let expiredTimer;

  const currentTime = Date.now();

  console.log("Current time: ");
  console.log(currentTime);
  console.log(exp);

  // Test token expires after 2 minutes
  // const timeLeft = currentTime + 120000 - currentTime;
  const timeLeft = exp * 1000 - currentTime;

  console.log("timeLeft: ");
  console.log(timeLeft);

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    alert('Token expired');

    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('user');

    window.location.href = paths.auth.jwt.login;
  }, timeLeft);
};

// ----------------------------------------------------------------------

export const setSession = (accessToken) => {
  if (accessToken) {
    console.log("Access token is saving: " + accessToken);
    sessionStorage.setItem('accessToken', accessToken);

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    tokenExpired(exp);
  } else {
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('user');
  }
};

export const getSessionToken = () => {
  return sessionStorage.getItem('accessToken');
};