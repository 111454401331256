import { useEffect, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';

// ----------------------------------------------------------------------

const icon = (name) => (
  // <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  <Iconify icon={name} />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('carbon:analytics'),
  dashboard: icon('ic_dashboard'),

  allEndUser: icon('emojione-monotone:couple-with-heart'),
  newEndUser: icon('clarity:new-solid'),

  allStaff: icon('raphael:employee'),
  newStaff: icon('ic:sharp-person-add'),

  masterData: icon('bx:data'),
  privilege: icon('hugeicons:access'),

  support: icon('bx:support'),

  profileInfo: icon('flowbite:profile-card-solid'),
  security: icon('mdi:security-off'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const data = useMemo(
    () => [
      // ANALYTICS
      // ----------------------------------------------------------------------
      {
        subheader: 'Analytics',
        items: [
          { title: 'view', path: paths.dashboard.analytics, icon: ICONS.analytics }
        ],
      },

      // END USER
      // ----------------------------------------------------------------------
      {
        subheader: 'End User',
        items: [
          { title: 'new', path: paths.dashboard.end_user.new, icon: ICONS.newEndUser },
          { 
            title: 'All', 
            path: paths.dashboard.end_user.all, 
            icon: ICONS.allEndUser,
            children: [
              { title: 'List', path: paths.dashboard.end_user.all },
              { title: 'Awaiting Id proofs', path: paths.dashboard.end_user.id_proof }
            ],
          }
        ],
      },

      // STAFF
      // ----------------------------------------------------------------------
      {
        subheader: 'Staff',
        items: [
          { title: 'all', path: paths.dashboard.staff.all, icon: ICONS.allStaff },
          { title: 'new', path: paths.dashboard.staff.new, icon: ICONS.newStaff }
        ],
      },

      // MASTER DATA
      // ----------------------------------------------------------------------
      {
        subheader: 'Master Data',
        items: [
          { title: 'data', path: paths.dashboard.master.data, icon: ICONS.masterData },
          { 
            title: 'control', 
            path: paths.dashboard.master.access_control, 
            icon: ICONS.privilege,
            children: [
              { title: 'Role', path: paths.dashboard.master.role },
              { title: 'Access control', path: paths.dashboard.master.access_control }
            ],
          }
        ],
      },

      // CHAT
      // ----------------------------------------------------------------------
      {
        subheader: 'Chat',
        items: [
          { 
            title: 'support', 
            path: paths.dashboard.chat.support, 
            icon: ICONS.support
          }
        ],
      },

      // PROFILE
      // ----------------------------------------------------------------------
      {
        subheader: 'Profile',
        items: [
          { title: 'Info', path: paths.dashboard.profile.info, icon: ICONS.profileInfo },
          { title: 'Security', path: paths.dashboard.profile.security, icon: ICONS.security }
        ],
      },
    ],
    []
  );

  return data;
}