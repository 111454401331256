import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const staffAPI = createApi({
    reducerPath: "staffAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllStaffs: builder.mutation({
            query: ({page, size, key}) => {
                let apiUrl = `staff/search?page=${page}&size=${size}`;

                if(key){
                    apiUrl += `&keyword=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        getStaffByUUID: builder.mutation({
            query: (uuid) => {
                let apiUrl = `staff/${uuid}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        createNewStaff: builder.mutation({
            query: (data) => {
                return {
                    url: `staff/create`,
                    method: "POST",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        updateStaff: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `staff/${id}`,
                    method: "PUT",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        deleteStaff: builder.mutation({
            query: (id) => {
                return {
                    url: `staff/${id}`,
                    method: "DELETE"
                };
            },
            transformResponse: (response) => response
        })
    })
});

export const { 
    useGetAllStaffsMutation,
    useGetStaffByUUIDMutation,
    useCreateNewStaffMutation,
    useUpdateStaffMutation,
    useDeleteStaffMutation
} = staffAPI;

export default staffAPI;