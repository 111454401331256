import { countries } from "src/assets/data";
import { CRYPTO } from 'src/config-global';
import CryptoJS from 'crypto-js';

export function getFileExtension(file) {
    if (file && file.name) {
        const fileNameParts = file.name.split(".");
        if (fileNameParts.length > 1) {
            return `.${fileNameParts[fileNameParts.length - 1]}`;
        }
    }
    return "";
}

export function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export function getPhoneByLabel(labelToFind) {
    const matchingItem = countries.find((item) => item.label === labelToFind);
    return matchingItem ? `+${matchingItem.phone}` : null;
}

export function formatInquiry(id) {
    const formattedId = id.trim().substring(4);
    return `DT-IN-${formattedId}`;
}

export function isRefNo(inquiry) {
    return inquiry !== "" && inquiry.startsWith("DTIN");
}

export function getModifiedMobileNumber(number) {
    if (number.substring(0, 3) !== "+94" && number[0] !== '0') {
        number = `+94${number}`;
    } else if (number[0] === '0') {
        number = `+94${number.substring(1, number.length)}`;
    } else if (number.substring(0, 3) === "+94" && number.length > 3 && number[3] === '0') {
        number = `+94${number.substring(4, number.length)}`;
    }

    return number;
}

export function validatePhoneNumber(value) {
    if (value === "") {
        return false;
    }

    value = getModifiedMobileNumber(value);

    return value.length === 12;
}

export function validateNIC(value) {
    let result = false;

    if (
        value.length === 10 &&
        !Number.isNaN(parseFloat(value.substring(0, 9))) &&
        Number.isNaN(parseFloat(value.substring(9))) &&
        ['x', 'v'].includes(value.substring(9).toLowerCase())
    ) {
        result = true;
    } else if (value.length === 12 && !Number.isNaN(parseFloat(value))) {
        result = true;
    }

    return result;
}

export function encrypt(data){
    const cipherText = CryptoJS.AES.encrypt(data, CRYPTO);
    console.log(cipherText);

    return cipherText;
}

export function decrypt(cipherText){
    const bytes = CryptoJS.AES.decrypt(cipherText, CRYPTO);
    var originalText = bytes.toString(CryptoJS.enc.Utf8);
    
    console.log(originalText);

    return originalText;
}