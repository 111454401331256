import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const endUserAPI = createApi({
    reducerPath: "endUserAPI",
    baseQuery,
    endpoints: (builder) => ({
        register: builder.mutation({
            query: (body) => ({
                url: `admin/user/create`,
                method: "POST",
                body
            }),
            transformResponse: (response) => response
        }),
        getAllEndUsers: builder.mutation({
            query: ({page, size, key, country, maritalStatus, profileStatus, gender, caste, dosha, minAge, maxAge}) => {
                let apiUrl = `admin/profile/filter?page=${page}&size=${size}`;

                if(key){
                    apiUrl += `&keyword=${key}`;
                }

                if(country){
                    apiUrl += `&country=${country}`;
                }

                if(maritalStatus){
                    apiUrl += `&maritalStatus=${maritalStatus}`;
                }

                if(profileStatus){
                    apiUrl += `&profileStatus=${profileStatus}`;
                }

                if(gender){
                    apiUrl += `&gender=${gender}`;
                }

                if(caste){
                    apiUrl += `&caste=${caste}`;
                }

                if(dosha){
                    apiUrl += `&dosha=${dosha}`;
                }

                if(minAge && maxAge){
                    apiUrl += `&minAge=${minAge}&maxAge=${maxAge}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        getAllValidationPendingEndUsers: builder.mutation({
            query: ({page, size, key}) => {
                let apiUrl = `admin/user/verify-id?page=${page}&size=${size}`;

                if(key){
                    apiUrl += `&keyword=${key}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        updateEndUser: builder.mutation({
            query: ({ uuid, data }) => {
                return {
                    url: `admin/profile/update/${uuid}`,
                    method: "PUT",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        activateEndUser: builder.mutation({
            query: (uuid) => {
                return {
                    url: `admin/profile/active/${uuid}`,
                    method: "PUT"
                };
            },
            transformResponse: (response) => response
        }),
        deactivateEndUser: builder.mutation({
            query: (uuid) => {
                return {
                    url: `admin/profile/deactivate/${uuid}`,
                    method: "PUT"
                };
            },
            transformResponse: (response) => response
        }),
        validateEndUserID: builder.mutation({
            query: ({uuid, status}) => {
                return {
                    url: `admin/user/verify-id/${uuid}?status=${status}`,
                    method: "PUT"
                };
            },
            transformResponse: (response) => response
        }),
        deleteEndUser: builder.mutation({
            query: ({uuid, reason}) => {
                return {
                    url: `admin/profile/delete/${uuid}?reason=${reason}`,
                    method: "DELETE"
                };
            },
            transformResponse: (response) => response
        })
    })
});

export const { 
    useRegisterMutation,
    useGetAllEndUsersMutation,
    useGetAllValidationPendingEndUsersMutation,
    useUpdateEndUserMutation,
    useActivateEndUserMutation,
    useDeactivateEndUserMutation,
    useValidateEndUserIDMutation,
    useDeleteEndUserMutation
} = endUserAPI;

export default endUserAPI;