import { m } from 'framer-motion';
import { useState, useCallback, useEffect, useRef } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _notifications } from 'src/_mock';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { varHover } from 'src/components/animate';
//
import NotificationItem from './notification-item';
import { useGetAllNotificationsMutation, useReadNotificationsMutation } from 'src/services/notifications-api';
import { useAuthContext } from 'src/auth/hooks';
import { useSnackbar } from 'notistack';
import { useNotificationContext } from 'src/auth/hooks/use-notification-context';
import { Menu } from '@mui/material';

// ----------------------------------------------------------------------

const TABS = [
  {
    value: 'all',
    label: 'All',
    count: 22,
  },
  {
    value: 'unread',
    label: 'Unread',
    count: 12,
  },
  {
    value: 'archived',
    label: 'Archived',
    count: 10,
  },
];

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const drawer = useBoolean();

  const smUp = useResponsive('up', 'sm');

  const [currentTab, setCurrentTab] = useState('all');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const { hasAccess } = useAuthContext();

  const [activePage, setActivePage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const useNotification = useNotificationContext();

  const [ getAllNotifications, {isLoading, isSuccess, isError, data: notificationData, error} ] = useGetAllNotificationsMutation();
  const [ readNotifications, {isSuccess: isSuccessReadNotifications, isError: isErrorReadNotifications, error: errorReadNotifications} ] = useReadNotificationsMutation();

  useEffect(() => {
    if(isSuccess && notificationData){
      console.log("Notifications...");
      console.log(notificationData);

      setNotifications([...notifications, ...notificationData.data.content]);
      setTotalPages(notificationData.data.page.totalPages);
    }
    else if(isError && error){
      console.log("Notifications error...");
      console.log(error);
    }
  }, [isSuccess, notificationData, isError, error]);

  useEffect(() => {
    if(isSuccessReadNotifications){
      console.log("Notifications read success...");
      
      useNotification.clearUnreadNotificationCount();
    }
    else if(isErrorReadNotifications && errorReadNotifications){
      console.log("Notifications read error...");
      console.log(errorReadNotifications);
    }
  }, [isSuccessReadNotifications, isErrorReadNotifications, errorReadNotifications]);

  const handleMarkAllAsRead = () => {
    setNotifications(
      notifications.map((notification) => ({
        ...notification,
        readAt: new Date(),
      }))
    );
  };

  const viewAllNotifications = useCallback(async () => {
    if(!hasAccess('SUPPORT_UPDATE')){
      enqueueSnackbar('You do not have permission to access this section', {variant: 'warning'});
      return;
    }

    if(isSuccessReadNotifications){
      handleMarkAllAsRead();
    }

    setNotifications([]);
    setActivePage(0);

    drawer.onTrue();

    await getAllNotifications({page: 0});
    await readNotifications();
  }, [isSuccessReadNotifications]);

  useEffect(() => {
    if(activePage > 0){
      getAllNotifications({page: activePage});
    }
  }, [activePage]);

  useEffect(() => {
    if(useNotification?.unreadNotificationCount > 0){
      enqueueSnackbar("You've received new notification", {variant: 'info'});

      // getAllNotifications({page: 0});
    }
  }, [useNotification]);

  const handleScroll = (event) => {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;

    if (bottom) {
      let page = activePage;
      page += 1;
      page = page > totalPages ? totalPages : page;

      setActivePage(page);
    }
  };

  const renderHead = (
    <Stack direction="row" alignItems="center" sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}>
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        Notifications
      </Typography>

      {/* {(isSuccess && notificationData) && (
        <Tooltip title="Mark all as read">
          <IconButton color="primary" onClick={handleMarkAllAsRead}>
            <Iconify icon="eva:done-all-fill" />
          </IconButton>
        </Tooltip>
      )} */}

      {!smUp && (
        <IconButton onClick={() => {
          drawer.onFalse();
        }}>
          <Iconify icon="mingcute:close-line" />
        </IconButton>
      )}
    </Stack>
  );

  const renderTabs = (
    <Tabs value={currentTab} onChange={handleChangeTab}>
      {TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            <Label
              variant={((tab.value === 'all' || tab.value === currentTab) && 'filled') || 'soft'}
              color={
                (tab.value === 'unread' && 'info') ||
                (tab.value === 'archived' && 'success') ||
                'default'
              }
            >
              {tab.count}
            </Label>
          }
          sx={{
            '&:not(:last-of-type)': {
              mr: 3,
            },
          }}
        />
      ))}
    </Tabs>
  );

  const renderList = (
    <Stack
      sx={{
        overflow: 'scroll',
        // height: '100vh'
      }}
      onScroll={handleScroll}
    >
      {
        notifications.map((notification) => (
          <NotificationItem key={notification.id} notification={notification} onClose={() => drawer.onFalse()}/>
        ))
      }
    </Stack>
  );

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        color={drawer.value ? 'primary' : 'default'}
        onClick={viewAllNotifications}
      >
        <Badge badgeContent={useNotification?.unreadNotificationCount} color="error">
          <Iconify icon="solar:bell-bing-bold-duotone" width={24} />
        </Badge>
      </IconButton>

      <Drawer
        open={drawer.value}
        onClose={drawer.onFalse}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 1, maxWidth: 420 },
        }}
      >
        {renderHead}

        <Divider />

        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ pl: 2.5, pr: 1 }}
        >
          {renderTabs}
          <IconButton onClick={handleMarkAllAsRead}>
            <Iconify icon="solar:settings-bold-duotone" />
          </IconButton>
        </Stack>

        <Divider /> */}

        {renderList}

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth size="large">
            View All
          </Button>
        </Box> */}
      </Drawer>
    </>
  );
}
