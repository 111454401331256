import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const sharedAPI = createApi({
    reducerPath: "sharedAPI",
    baseQuery,
    endpoints: (builder) => ({
        getUploadNDownloadURL: builder.mutation({
            query: ({type, extension}) => {
                let apiUrl = `images/upload-and-download-url?type=${type}&extension=${extension}`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        })
    })
});

export const { 
    useGetUploadNDownloadURLMutation
} = sharedAPI;

export default sharedAPI;