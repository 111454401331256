// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      forgotPassword: `${ROOTS.AUTH}/jwt/forgot-password`
    },
  },
  // DASHBOARD
  dashboard: {
    root: `${ROOTS.DASHBOARD}/analytics`,
    analytics: `${ROOTS.DASHBOARD}/analytics`,
    end_user: {
      root: `${ROOTS.DASHBOARD}/end_user/all`,
      all: `${ROOTS.DASHBOARD}/end_user/all`,
      id_proof: `${ROOTS.DASHBOARD}/end_user/id_proof`,
      new: `${ROOTS.DASHBOARD}/end_user/new`
    },
    staff: {
      root: `${ROOTS.DASHBOARD}/staff/all`,
      all: `${ROOTS.DASHBOARD}/staff/all`,
      new: `${ROOTS.DASHBOARD}/staff/new`
    },
    master: {
      root: `${ROOTS.DASHBOARD}/master/data`,
      data: `${ROOTS.DASHBOARD}/master/data`,
      role: `${ROOTS.DASHBOARD}/master/role`,
      access_control: `${ROOTS.DASHBOARD}/master/access-control`
    },
    chat: {
      root: `${ROOTS.DASHBOARD}/chat/support`,
      support: `${ROOTS.DASHBOARD}/chat/support`
    },
    profile: {
      root: `${ROOTS.DASHBOARD}/profile/info`,
      info: `${ROOTS.DASHBOARD}/profile/info`,
      security: `${ROOTS.DASHBOARD}/profile/security`
    }
  },
};