import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOST_API } from 'src/config-global';

const authAPI = createApi({
    reducerPath: "authAPI",
    baseQuery: fetchBaseQuery({baseUrl: HOST_API}),
    endpoints: (builder) => ({
        getCustomEmail: builder.mutation({
            query: (userName) => {
                let apiUrl = `auth/firebase/email`;

                if (userName) {
                    apiUrl += `?username=${userName}`;
                }

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response
        }),
        customLogin: builder.mutation({
            query: (idToken) => {
                let apiUrl = `auth/login`;
                
                if (idToken) {
                    apiUrl += `/${idToken}`;
                }

                return {
                    url: apiUrl,
                    method: "POST"
                };
            },
            transformResponse: (response) => response
        }),
        forgotPassword: builder.mutation({
            query: (email) => ({
                url: `auth/forgot-password?email=${email}`,
                method: "POST"
            }),
            transformResponse: (response) => response
        }),
    })
});

export const { 
    useGetCustomEmailMutation,
    useCustomLoginMutation,
    useForgotPasswordMutation
} = authAPI;

export default authAPI;