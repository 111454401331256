import { configureStore, createSlice } from '@reduxjs/toolkit';

import authAPI from "../services/auth-api";
import masterDataAPI from "../services/master-data-api";
import privilegeAPI from 'src/services/privilege-api';
import rolesAPI from 'src/services/roles-api';
import staffAPI from 'src/services/staff-api';
import supportAPI from 'src/services/support-api';
import sharedAPI from 'src/services/shared-api';
import endUserAPI from 'src/services/end-user-api';
import s3UploadAPI from 'src/services/s3-upload-api';
import notificationAPI from 'src/services/notifications-api';
import analyticsAPI from 'src/services/analytics-api';

const homeSlice = createSlice({
  name: "home",
  initialState: {
    user: null,
    highlightChatID: null
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setHighlightChatID: (state, action) => {
      state.highlightChatID = action.payload;
    }
  }
});

export const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [masterDataAPI.reducerPath]: masterDataAPI.reducer,
    [privilegeAPI.reducerPath]: privilegeAPI.reducer,
    [rolesAPI.reducerPath]: rolesAPI.reducer,
    [staffAPI.reducerPath]: staffAPI.reducer,
    [supportAPI.reducerPath]: supportAPI.reducer,
    [sharedAPI.reducerPath]: sharedAPI.reducer,
    [s3UploadAPI.reducerPath]: s3UploadAPI.reducer,
    [endUserAPI.reducerPath]: endUserAPI.reducer,
    [notificationAPI.reducerPath]: notificationAPI.reducer,
    [analyticsAPI.reducerPath]: analyticsAPI.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat(authAPI.middleware)
    .concat(masterDataAPI.middleware)
    .concat(privilegeAPI.middleware)
    .concat(rolesAPI.middleware)
    .concat(staffAPI.middleware)
    .concat(supportAPI.middleware)
    .concat(sharedAPI.middleware)
    .concat(s3UploadAPI.middleware)
    .concat(endUserAPI.middleware)
    .concat(notificationAPI.middleware)
    .concat(analyticsAPI.middleware)
})

export const homeActions = homeSlice.actions;