// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// routes
import { paths } from 'src/routes/paths';
// locales
// components
import Label from 'src/components/label';
import { HOST_ENV, APP_VERSION } from 'src/config-global';
import useFirebaseApp from 'src/hooks/use-firebase-app';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
// ----------------------------------------------------------------------

export default function NavUpgrade() {
  const { user } = useMockedUser();

  const userData = useSelector((state) => state.home.user);

  const [userName, setUserName] = useState("");

  useEffect(() => {
    if(userData){
      setUserName(userData.firstName);
    }
  }, [userData]);

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.photoURL} alt={userName} sx={{ width: 48, height: 48 }} />
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {userName}
          </Typography>
        </Stack>

        <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
          {HOST_ENV} v{APP_VERSION}
        </Typography>
      </Stack>
    </Stack>
  );
}
