import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
//
import { RTNotificationContext } from './rt-notification-context';
import { getDatabase, ref, onValue, limitToLast, query } from "firebase/database";
import { useAuthContext } from 'src/auth/hooks';
import useFirebaseApp from 'src/hooks/use-firebase-app';

export function RTNotificationProvider({ children }) {
    const authContext = useAuthContext();
    const { firebaseApp } = useFirebaseApp();
    const database = getDatabase(firebaseApp);

    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    useEffect(() => {
        if(authContext?.authenticated){
            console.log("Start to listen the notifications");

            const notificationRef = query(ref(database, 'notification/admin'), limitToLast(1));

            onValue(notificationRef, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received new notification");

                    snapshot.forEach((notification) => {
                        console.log(notification.val());
                        setUnreadNotificationCount(notification.val());
                    });
                }
            });
        }
    }, [authContext]);

    const clearUnreadNotificationCount = useCallback(() => {
        setUnreadNotificationCount(0);
    }, []);

    const memoizedValue = useMemo(
        () => ({
            unreadNotificationCount,
            clearUnreadNotificationCount
        }),
        [unreadNotificationCount, clearUnreadNotificationCount]
    );

    return <RTNotificationContext.Provider value={memoizedValue}>
        {children}
    </RTNotificationContext.Provider>;
}

RTNotificationProvider.propTypes = {
  children: PropTypes.node,
};