import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
//
import { RTChatContext } from './rt-chat-context';
import { getDatabase, ref, onValue, limitToLast, query } from "firebase/database";
import { useGetAllChatsListMutation } from 'src/services/support-api';
import { useAuthContext } from 'src/auth/hooks';
import useFirebaseApp from 'src/hooks/use-firebase-app';

export function RTChatProvider({ children }) {
    const authContext = useAuthContext();
    const { firebaseApp } = useFirebaseApp();
    const database = getDatabase(firebaseApp);

    const [initialPageLoadIndex, setInitialPageLoadIndex] = useState(0);
    const [listeningIDList, setListeningIDList] = useState([]);

    const [newlyReceivedMessageList, setNewlyReceivedMessageList] = useState([]);
    const [unreadUserMessagesExist, setUnreadUserMessagesExist] = useState([]);

    const [
        getAllChats,
        {
            isLoading,
            isSuccess,
            data,
            isError,
            error
        }
    ] = useGetAllChatsListMutation();

    useEffect(() => {
        if(authContext?.authenticated){
            console.log("Call chat API with page " + initialPageLoadIndex);
            getAllChats(initialPageLoadIndex);
        }
    }, [authContext, initialPageLoadIndex]);

    useEffect(() => {
        if (isSuccess && data) {
            console.log("Get all chats success");
            console.log(data);
            
            const content = data.data.content;
            const idList = [];

            for(let i = 0; i < content.length; i++){
                idList.push(content[i].id);
            }

            updateListeningIDsList(idList);
        }
        else if(isError && error){
            console.log("Get all chats failed");
            console.log(error);

            if(listeningIDList.length > 0){
                listenToNewMessage();
            }
        }
    }, [isSuccess, data, isError, error, initialPageLoadIndex]);

    const updateListeningIDsList = useCallback((idList) => {
        const newIdList = [];
        const unreadUserList = [];

        for(let i = 0; i < idList.length; i++){
            if(!listeningIDList.includes(idList[i])){
                newIdList.push(idList[i]);
                unreadUserList.push({id: idList[i], unRead: 0});
            }
        }

        setListeningIDList([...listeningIDList, ...newIdList]);
        setUnreadUserMessagesExist([...unreadUserMessagesExist, ...unreadUserList]);
    }, [listeningIDList, unreadUserMessagesExist]);

    useEffect(() => {
        if(listeningIDList.length > 0){
            console.log("New listening id list updated");
            console.log(listeningIDList);

            if(data && initialPageLoadIndex < data.data.page.totalPages - 1){
                setInitialPageLoadIndex((index) => index + 1);
            }
            else if(data && data.data.page.totalElements === listeningIDList.length){
                console.log("Ready to listen the chats...");
                listenToNewMessage();
            }
        }
    }, [listeningIDList]);

    const listenToNewMessage = useCallback(() => {
        for(let i = 0; i < listeningIDList.length; i++){
            const userID = listeningIDList[i];

            console.log("Start to listen the chat from the user " + userID);

            const chatRef = query(ref(database, 'admin/chat/' + userID), limitToLast(1));
            const chatCountRef = query(ref(database, 'chat/admin/' + userID));

            //Listen to all messages
            onValue(chatRef, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received new message from the user " + userID);

                    const newMsgArray = [];

                    snapshot.forEach((message) => {
                        console.log(message.val());
                        newMsgArray.push(message.val());
                    });

                    setNewlyReceivedMessageList(newMsgArray);
                }
            });

            // Listen to new message count
            onValue(chatCountRef, (snapshot) => {
                if(snapshot.exists()){
                    console.log("Received new message count from the user " + userID);
                    console.log(snapshot.val());

                    const unreadUserMessageList = [...unreadUserMessagesExist];

                    for(let i = 0; i < unreadUserMessageList.length; i++){
                        if(unreadUserMessageList[i].id === userID){
                            unreadUserMessageList[i].unRead = snapshot.val().unreadAdminMessage;
                        }
                    }

                    setUnreadUserMessagesExist(unreadUserMessageList);
                }
            });
        }
    }, [listeningIDList, newlyReceivedMessageList, unreadUserMessagesExist]);

    const getTotalUnreadSpecificUserMessagesCount = useCallback((userId) => {
        for(let i = 0; i < unreadUserMessagesExist.length; i++){
            if(unreadUserMessagesExist[i].id === userId){
                return unreadUserMessagesExist[i].unRead;
            }
        }

        return 0;
    }, [unreadUserMessagesExist]);

    const clearUserUnreadStatus = useCallback((userID) => {
        const unreadUserMessageList = [...unreadUserMessagesExist];
        
        for(let i = 0; i < unreadUserMessageList.length; i++){
            if(unreadUserMessageList[i].id === userID){
                unreadUserMessageList[i].unRead = 0;
                break;
            }
        }
        
        setUnreadUserMessagesExist(unreadUserMessageList);
    }, [unreadUserMessagesExist]);

    const memoizedValue = useMemo(
        () => ({
            newlyReceivedMessageList,
            getTotalUnreadSpecificUserMessagesCount,
            clearUserUnreadStatus
        }),
        [newlyReceivedMessageList, getTotalUnreadSpecificUserMessagesCount, clearUserUnreadStatus]
    );

    return <RTChatContext.Provider value={memoizedValue}>
        {children}
    </RTChatContext.Provider>;
}

RTChatProvider.propTypes = {
  children: PropTypes.node,
};