import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const rolesAPI = createApi({
    reducerPath: "rolesAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllRoles: builder.mutation({
            query: () => {
                let apiUrl = `role`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        createNewRole: builder.mutation({
            query: (data) => {
                return {
                    url: `role`,
                    method: "POST",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        updateRole: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `role/${id}`,
                    method: "PUT",
                    body: data
                };
            },
            transformResponse: (response) => response
        })
    })
});

export const { 
    useGetAllRolesMutation,
    useCreateNewRoleMutation,
    useUpdateRoleMutation
} = rolesAPI;

export default rolesAPI;