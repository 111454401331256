import { useContext } from 'react';
//
import { RTNotificationContext } from '../context/notification/rt-notification-context';

// ----------------------------------------------------------------------

export const useNotificationContext = () => {
  const context = useContext(RTNotificationContext);

  if (!context) throw new Error('useChatContext context must be use inside ChatProvider');

  return context;
};