import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const s3UploadAPI = createApi({
    reducerPath: "s3UploadAPI",
    baseQuery: fetchBaseQuery({baseUrl: process.env.REACT_APP_HOST_API}),
    endpoints: (builder) => ({
        uploadToS3: builder.mutation({
            query: ({ uploadUrl, file }) => ({
                url: `${uploadUrl}`,
                method: "PUT",
                body: file
            })
        })
    })
});

export const { 
    useUploadToS3Mutation
} = s3UploadAPI;

export default s3UploadAPI;