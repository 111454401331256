import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const privilegeAPI = createApi({
    reducerPath: "privilegeAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllPrivileges: builder.mutation({
            query: () => {
                let apiUrl = `privilege`;

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
            
        }),
        createNewPrivilege: builder.mutation({
            query: (data) => {
                return {
                    url: `privilege`,
                    method: "POST",
                    body: data
                };
            },
            transformResponse: (response) => response
        }),
        updatePrivilege: builder.mutation({
            query: ({ id, data }) => {
                return {
                    url: `privilege/${id}`,
                    method: "PUT",
                    body: data
                };
            },
            transformResponse: (response) => response
        })
    })
});

export const { 
    useGetAllPrivilegesMutation,
    useCreateNewPrivilegeMutation,
    useUpdatePrivilegeMutation
} = privilegeAPI;

export default privilegeAPI;