import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { getSessionToken } from '../auth/context/jwt/utils';

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_HOST_API,
    prepareHeaders: (headers) => {
        const accessToken = getSessionToken();

        console.log("Access Token: " + accessToken);

        headers.set('Authorization', `Bearer ${accessToken}`);
        headers.set('Content-Type', 'application/json');
        return headers
    },
    timeout: 60000
});