import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------
const IndexPage = lazy(() => import('src/pages/dashboard/analytics/analytics'));
const PageAnalytics = lazy(() => import('src/pages/dashboard/analytics/analytics'));

const PageAllEndUser = lazy(() => import('src/pages/dashboard/end-user/all'));
const PageEndUserIDProofs = lazy(() => import('src/pages/dashboard/end-user/id-proof'));
const PageNewEndUser = lazy(() => import('src/pages/dashboard/end-user/new'));

const PageAllStaff = lazy(() => import('src/pages/dashboard/staff/all'));
const PageNewStaff = lazy(() => import('src/pages/dashboard/staff/new'));

const MasterData = lazy(() => import('src/pages/dashboard/master-data/data'));
const RoleManagement = lazy(() => import('src/pages/dashboard/master-data/role'));
const PrivilegeManagement = lazy(() => import('src/pages/dashboard/master-data/privilege'));
const AccessControl = lazy(() => import('src/pages/dashboard/master-data/access-control'));

const PageSupport = lazy(() => import('src/pages/dashboard/chat/support'));

const ProfileInfo = lazy(() => import('src/pages/dashboard/profile/info'));
const PageProfileSecurity = lazy(() => import('src/pages/dashboard/profile/security'));
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'analytics', element: <PageAnalytics /> },
      {
        path: 'end_user',
        children: [
          { element: <PageAllEndUser />, index: true },
          { path: 'all', element: <PageAllEndUser /> },
          { path: 'id_proof', element: <PageEndUserIDProofs /> },
          { path: 'new', element: <PageNewEndUser /> }
        ],
      },
      {
        path: 'staff',
        children: [
          { element: <PageAllStaff />, index: true },
          { path: 'all', element: <PageAllStaff /> },
          { path: 'new', element: <PageNewStaff /> }
        ],
      },
      {
        path: 'master',
        children: [
          { element: <MasterData />, index: true },
          { path: 'data', element: <MasterData /> },
          { path: 'role', element: <RoleManagement /> },
          { path: 'access-control', element: <AccessControl /> }
        ],
      },
      {
        path: 'chat',
        children: [
          { element: <PageSupport />, index: true },
          { path: 'support', element: <PageSupport /> }
        ],
      },
      {
        path: 'profile',
        children: [
          { element: <ProfileInfo />, index: true },
          { path: 'info', element: <ProfileInfo /> },
          { path: 'security', element: <PageProfileSecurity /> }
        ]
      }
    ]
  }
];