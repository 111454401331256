import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from './base-query';

const notificationAPI = createApi({
    reducerPath: "notificationAPI",
    baseQuery,
    endpoints: (builder) => ({
        getAllNotifications: builder.mutation({
            query: ({page}) => {
                let apiUrl = `notification/admin?page=${page}&size=10`; 

                return {
                    url: apiUrl,
                    method: "GET",
                };
            },
            transformResponse: (response) => response,
        }),
        readNotifications: builder.mutation({
            query: () => {
                let apiUrl = `notification/admin/read`;

                return {
                    url: apiUrl,
                    method: "PUT",
                };
            },
            transformResponse: (response) => response,
        })
    })
});

export const { 
    useGetAllNotificationsMutation,
    useReadNotificationsMutation
} = notificationAPI;

export default notificationAPI;